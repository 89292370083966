:root {
	--primary-color: #8fbd56;
	/** primary **/
	--ion-color-primary: #8fbd56;
	--ion-color-primary-rgb: 56, 128, 255;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255, 255, 255;
	--ion-color-primary-shade: #25c87c;
	--ion-color-primary-tint: #20c076;

	/** secondary **/
	--ion-color-secondary: #8fbd56;
	--ion-color-menu: #a5ffd5;
	--ion-color-secondary-rgb: 61, 194, 255;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255, 255, 255;
	--ion-color-secondary-shade: #9dce62;
	--ion-color-secondary-tint: #50ff5f;

	// /** tertiary **/
	--ion-color-tertiary: #6c9935;
	--ion-color-tertiary-rgb: 82, 96, 255;
	--ion-color-tertiary-contrast: #ffffff;
	--ion-color-tertiary-contrast-rgb: 255, 255, 255;
	--ion-color-tertiary-shade: #18b169;
	--ion-color-tertiary-tint: #198754;

	// /** success **/
	--ion-color-success: #2dd36f;
	--ion-color-success-rgb: 45, 211, 111;
	--ion-color-success-contrast: #ffffff;
	--ion-color-success-contrast-rgb: 255, 255, 255;
	--ion-color-success-shade: #28ba62;
	--ion-color-success-tint: #42d77d;

	// /** warning **/
	 --ion-color-warning: #f1d313;
	 --ion-color-error: #bb6262;
	// --ion-color-warning-rgb: 255, 196, 9;
	// --ion-color-warning-contrast: #000000;
	// --ion-color-warning-contrast-rgb: 0, 0, 0;
	// --ion-color-warning-shade: #e0ac08;
	// --ion-color-warning-tint: #ffca22;

	// /** danger **/
	// --ion-color-danger: #eb445a;
	// --ion-color-danger-rgb: 235, 68, 90;
	// --ion-color-danger-contrast: #ffffff;
	// --ion-color-danger-contrast-rgb: 255, 255, 255;
	// --ion-color-danger-shade: #cf3c4f;
	// --ion-color-danger-tint: #ed576b;

	// /** dark **/
	// --ion-color-dark: #222428;
	// --ion-color-dark-rgb: 34, 36, 40;
	// --ion-color-dark-contrast: #ffffff;
	// --ion-color-dark-contrast-rgb: 255, 255, 255;
	// --ion-color-dark-shade: #1e2023;
	// --ion-color-dark-tint: #383a3e;

	// /** medium **/
	--ion-color-medium: #ffffff;
	--ion-color-medium-rgb: 146, 148, 156;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 255, 255, 255;
	--ion-color-medium-shade: #808289;
	--ion-color-medium-tint: #9d9fa6;

	// /** light **/
	--ion-color-light: #ffffff;
	--ion-color-light-rgb: 244, 245, 248;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0, 0, 0;
	--ion-color-light-shade: #d7d8da;
	--ion-color-light-tint: #f5f6f9;
	--ion-background-color: #8fbd56;

}

.but-primary {
	background: var(--primary-color);
}
.alert-wrapper.sc-ion-alert-md  {
	background-color: #ffffff !important; /* Cambia este color al que desees */
  }
.txt-primary {
	color: var(--primary-color) !important;
}

.txt-white {
	color: #fff;
}
.my-custom-class{
	--ion-background-color:#ffffff
}
.txt-danger {
	color: #b71717;
}
ion-content{
	--ion-background-color:#ffffff;
	--border-radius: 16px 16px 0px 0px;
}
.btn {
	min-height: 4em;
}
.btn-icon {
	min-height: 2em !important;
	display: inline-block !important;
font-weight: 400 !important;
text-align: center !important;
white-space: nowrap !important;
vertical-align: middle !important;
-webkit-user-select: none !important;
-moz-user-select: none !important;
-ms-user-select: none !important;
user-select: none !important;
border: 1px solid transparent !important;
padding: 0.75rem 1em !important;
font-size: 1rem !important;
border-radius: 4px !important;
transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

}
.span-color{
	color: #8fbd56;
}
.grid-max{
	max-width: 729px;
  }
.span-right{
	margin-right: 11px;
  }
  .img-post{
	width: 200px; height: 250px;
  } 
.btns {
	min-height: 4em;
}
.thead-green{
	--bs-table-bg: #8fbd56;
  }
  .p-detail{
	word-wrap: break-word !important; line-height: 1.5;
	white-space: normal !important;
  }
.white{
	background: white;
  }
.green-p{
	color: #34b530;
}

.paddinng10{
	padding: 10px;
  }
  .red-color{
	color: red;
  }
  .pd-b{
	padding-bottom: 5px;
  }
.margin-10{
	margin-bottom: 10px;
}
.margin-r5{
	margin-right: 5px;
}
.item-reserva{
	overflow: hidden;  text-overflow: ellipsis;
}
.btn-primary {
	background: var(--primary-color) !important;
	color: #fff;
	border-radius: .5em;
	width: 100%;
}
.btn-primarys{
	background: #8fbd56 !important;
	color: #fff;
	border-radius: .5em;
	width: 100%;
}
ion-input{
	border-radius: 10px !important;
	padding-right: 30px;
	font-size: 0.9em;
	border: 1px solid #f1f1f1 !important;
	--background: rgb(240, 240, 240) !important;
	/* height: 10%; */
	/* width: 41%; */
	
}
.button-unselected {
	background: white;
	color: var(--ion-color-primary);
	border: 1px solid var(--ion-color-primary);
  }
ion-card {


	ion-item ion-label p {
		color: var(--primary-color) !important;
	}
}

ion-item {
	--padding-start: 0px;
	--padding-end: 0px;
	--inner-padding-end: 0px;
	--inner-padding-start: 0px;
}

ion-icon {
	color: var(--primary-color);
}

.col-cont-center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.btn-margin{
	margin-left: 125px;
	margin-right: 125px;	
}
.col-cont-right {
    display: flex;
    align-items: center;
    justify-content: right;
}
// ***** POPOVER *****
ion-popover {
	// --background: var(--primary-color) !important;
	--width: 75% !important;
	--backdrop-opacity: .5;
}

// Reduccion padding derecho de searchbar
ion-searchbar {
	input {
		padding-right: 10px !important;
		background-color: #e0e0e0 !important;
	}
}
ion-modal{

	--background: white;

		--height: 100% !important; 
	
}
.modal-term{

	--background: transparent;

		--height: 85% !important; 
	

}
.modalsearch{

	--background: transparent;

		--height: 85% !important; 
	
}
app-term {
	border-radius: 12px 12px 0px 0px;
}
@import "bootstrap"